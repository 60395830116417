import { ModalDialog } from "@bluesilodev/timhutcomponents";
import ChangeBorderProvider from "context/changeBorder";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import PublishDetailData from "./publishDetailData";

import { setDataDate, setDateRange } from "store/employeeViewSlice";
import { generateDateRange } from "utils/common/generateDateRange";
import AllButton from "./allButton";

function AllButtons() {
  // SHIFT DATE TEMPLATE
  const [confirmationPublish, setConfirmationPublish] = useState(false);

  // FOR STATUS CONFLICK AND REPLACE

  // locationState
  // const { statusConflick, statusReplace } = useSelector(
  //   (state) => state.employeeView
  // );

  const dispatch = useDispatch();

  // GET ALL DATA DATE
  const today = dayjs().day(1).toDate();
  const nextWeek = dayjs().day(1).add(6, "day").toDate();

  const resultDefault = generateDateRange(today, nextWeek);

  const [dateValue, setDateValue] = useState(
    //   [
    //   dayjs().startOf("week").toDate(),
    //   dayjs().endOf("week").toDate(),
    // ]
    resultDefault
  );

  // SET VALUE DATE INCLUDES STARTDATE AND ENDDATE
  const { values, setFieldValue } = useFormik({
    initialValues: {
      date: dateValue,
    },
  });

  useEffect(() => {
    setFieldValue("date", dateValue);
  }, [dispatch]);

  dispatch(setDataDate(values.date));

  // VALUES DATE FOR EMPLOYEE MAP / ASSIGN SHIFT
  dispatch(
    setDateRange({
      fromDate: values.date[0],
      toDate: values.date[values.date.length - 1],
    })
  );

  return (
    <ChangeBorderProvider valuesDate={values.date}>
      <div className="w-full">
        <AllButton
          setFieldValue={setFieldValue}
          setDateValue={setDateValue}
          values={values}
          showConfirmPublish={() => {
            setConfirmationPublish(true);
          }}
        />
      </div>

      {/* Modal PUBLISH BASE ON LOCATION */}
      {confirmationPublish && (
        <ModalDialog
          title={"Publish Shift"}
          className={"w-[700px] h-[700px]"}
          onClose={() => setConfirmationPublish(false)}
        >
          <PublishDetailData
            values={values}
            setConfirmationPublish={setConfirmationPublish}
          />
        </ModalDialog>
      )}
    </ChangeBorderProvider>
  );
}

export default AllButtons;
